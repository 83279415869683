import { render, staticRenderFns } from "./index.vue?vue&type=template&id=709ff6c8&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=709ff6c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "709ff6c8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingWordSlider: require('/build/d4214a52-8d7b-4236-9ce4-635fa71443e2/nuxt/components/Landing/WordSlider.vue').default,LandingSearchForm: require('/build/d4214a52-8d7b-4236-9ce4-635fa71443e2/nuxt/components/Landing/SearchForm.vue').default,LandingSlidingTabs: require('/build/d4214a52-8d7b-4236-9ce4-635fa71443e2/nuxt/components/Landing/SlidingTabs.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
